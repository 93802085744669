import styles from "./usersPage.module.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useAppSelector } from "../../services/hooks";
import { adminSelector } from "../../services/selectors/user.selectors";
import {
  useAddTherapistMutation,
  useLazyGetAllCombosForSearchUsersQuery,
  useLazyGetUsersByCompanyIdWithRecommendsQuery,
  useTakeUserMutation,
} from "../../services/api/user.api";
import Modal from "../../components/modals/modal/Modal";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import {
  userCategories,
  userCategoriesWithFakeCompanyId,
} from "../../utils/categories";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import {
  companiesSelector,
  recommendationsSelector,
  usersSelector,
} from "../../services/selectors/users.selectors";
import Loader from "../../components/loader/loader";
import CloseButton from "../../components/closeButton/closeButton";
import AddButton from "../../components/addButton/addButton";
import AddNewUserForm from "../../components/forms/addNewUserForm/addNewUserForm";
import UsersList from "../../components/usersList/usersList";
import { TUserForm } from "../../types/types";
import { TTakeUserRequest } from "../../services/types/user.types";
import { filterObjectsByString } from "../../utils/search";
import Select from "react-select";

function UsersPage() {
  const admin = useAppSelector(adminSelector);
  const recommendations = useAppSelector(recommendationsSelector);
  const companies = useAppSelector(companiesSelector);
  const users = useAppSelector(usersSelector);

  const [getAllCombosForSearchUsers] = useLazyGetAllCombosForSearchUsersQuery();
  const [getAllUsersByCompanyIdWithRecommends] =
    useLazyGetUsersByCompanyIdWithRecommendsQuery();
  const [takeUser] = useTakeUserMutation();
  const [addTherapist] = useAddTherapistMutation();

  const [userList, setUserList] = useState(users);
  const [recommendationsId, setRecommendationsId] = useState(0);
  const [companyId, setCompanyId] = useState(0);
  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [inputValue, setInputValue] = useState<string>("");
  const [usersCategory, setUsersCategory] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    if (admin.companyId) {
      getAllCombosForSearchUsers(admin.fakeCompanyId ?? admin.companyId);
    }
  }, [admin, getAllCombosForSearchUsers]);

  useEffect(() => {
    setUserList(users);
  }, [users]);

  useEffect(() => {
    if (admin.fakeCompanyId) {
      setCompanyId(admin.fakeCompanyId);
    }
  }, [admin.fakeCompanyId]);

  const sendTakeUserFormToServer = (values: TUserForm) => {
    let payload: TTakeUserRequest = {
      Id: "0",
      TherapistsId: admin.id,
      FirstName: "",
      GenderId: 3,
      CompanyId: admin.companyId,
    };
    for (let key in values) {
      payload[key.charAt(0).toUpperCase() + key.slice(1)] = values[key];
    }
    if (usersCategory === "client") {
      takeUser({ ...payload, FakeCompanyId: admin.fakeCompanyId }).then(
        (res) => {
          // @ts-ignore
          if ("error" in res && res?.error?.originalStatus !== 200) {
            setOpenErrorModal(true);
          } else {
            setOpenSuccessModal(true);
          }
        }
      );
    }
    if (usersCategory === "therapist") {
      addTherapist({
        companyId: admin.companyId,
        body: {
          ...payload,
          RoleId: 3,
          CompanyId: admin.companyId,
          FakeCompanyId: admin.fakeCompanyId,
        },
      }).then((res) => {
        // @ts-ignore
        if ("error" in res && res?.error?.originalStatus !== 200) {
          setOpenErrorModal(true);
        } else {
          setOpenSuccessModal(true);
        }
      });
    }
  };
  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setUserList(() => filterObjectsByString(event.target.value, users));
  };

  const categories =
    admin.fakeCompanyId || admin.FakeCompanyId
      ? userCategoriesWithFakeCompanyId
      : userCategories;

  return (
    <section className={styles.users}>
      {isLoading ? (
        <article className={styles.loaderContainer}>
          <Loader />
        </article>
      ) : usersCategory ? (
        <article className={styles.usersTable}>
          <BackArrowButton
            text={"למסך הקוד"}
            onClick={() => {
              setUsersCategory(undefined);
              setCompanyId(-1);
              setRecommendationsId(0);
              setShowForm(false);
            }}
            position={"center"}
          />
          <div className={styles.addButtonContainer}>
            {showForm ? (
              <CloseButton onClick={() => setShowForm(false)} />
            ) : admin.fakeCompanyId === null || admin.FakeCompanyId === null ? (
              <AddButton
                onClick={() => {
                  setShowForm(true);
                }}
                title={"הוסף משתמש חדש"}
              />
            ) : null}
          </div>
          {showForm && <AddNewUserForm onSubmit={sendTakeUserFormToServer} />}
          <div className={styles.usersContent}>
            <div className={styles.usersSearchBar}>
              <input
                type={"text"}
                placeholder={"חיפוש"}
                className={styles.usersInput}
                onChange={onChangeValue}
                value={inputValue}
              />
              <Select
                name={"filter"}
                options={recommendations}
                value={
                  recommendations.find((r) => r.value === recommendationsId) ||
                  undefined
                }
                placeholder={"אנא בחר... "}
                className={styles.select}
                onChange={(value) => {
                  setRecommendationsId(value?.Id || 0);
                  setInputValue("");
                  getAllUsersByCompanyIdWithRecommends({
                    companyId: admin.companyId,
                    roleId: usersCategory
                      ? usersCategory === "client"
                        ? 1
                        : 2
                      : 0,
                    recommendsId: value?.Id || 0,
                    fakeCompanyId: admin.fakeCompanyId || 0,
                  });
                }}
              />
              {admin.fakeCompanyId === null && (
                <Select
                  name={"companies"}
                  options={companies}
                  value={
                    companies.find((c) => c.value === companyId) || undefined
                  }
                  placeholder={"אנא בחר... "}
                  className={styles.select}
                  onChange={(value) => {
                    setCompanyId(value?.Id || 0);
                    setInputValue("");
                    getAllUsersByCompanyIdWithRecommends({
                      companyId: admin.companyId,
                      roleId: usersCategory
                        ? usersCategory === "client"
                          ? 1
                          : 2
                        : 0,
                      fakeCompanyId: value?.Id || 0,
                      recommendsId: recommendationsId,
                    });
                  }}
                />
              )}
            </div>
            {users && users.length > 0 ? (
              <UsersList userList={userList} usersCategory={usersCategory} />
            ) : (
              <p className={styles.noUsers}>לא נמצאו משתמשים</p>
            )}
            <p className={styles.usersTotal}>
              סה"כ משתמשים:
              {users?.length || "0"}
            </p>
          </div>
        </article>
      ) : (
        <ul className={`options ${styles.options}`}>
          {categories.map((cat) => (
            <p
              key={cat.value}
              className={`option hover`}
              onClick={() => {
                setIsLoading(true);
                getAllUsersByCompanyIdWithRecommends({
                  companyId: admin.companyId,
                  fakeCompanyId: admin.fakeCompanyId ?? 0,
                  roleId: cat.value === "client" ? 1 : 2,
                }).then((res) => {
                  setIsLoading(false);
                  if ("data" in res) {
                    setUsersCategory(cat.value);
                  } else {
                    setOpenErrorModal(true);
                  }
                });
              }}
            >
              {cat.label}
            </p>
          ))}
        </ul>
      )}

      {openErrorModal && (
        <Modal
          onClose={() => {
            setOpenErrorModal(false);
          }}
        >
          <ErrorPopup
            onClose={() => {
              setOpenErrorModal(false);
            }}
          />
        </Modal>
      )}
      {openSuccessModal && (
        <Modal
          onClose={() => {
            setOpenSuccessModal(false);
            setShowForm(false);
            getAllUsersByCompanyIdWithRecommends({
              companyId: admin.companyId,
              roleId: usersCategory ? (usersCategory === "client" ? 1 : 2) : 0,
              fakeCompanyId: companyId,
              recommendsId: recommendationsId,
            });
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenSuccessModal(false);
              setShowForm(false);
              getAllUsersByCompanyIdWithRecommends({
                companyId: admin.companyId,
                roleId: usersCategory
                  ? usersCategory === "client"
                    ? 1
                    : 2
                  : 0,
                fakeCompanyId: companyId,
                recommendsId: recommendationsId,
              });
            }}
          />
        </Modal>
      )}
    </section>
  );
}

export default UsersPage;
