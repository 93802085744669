import styles from "./editPersonalDataPage.module.css";
import React, { useEffect, useState } from "react";
import avatarPlaceholder from "../../images/avatar_placeholder_purple.svg";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import { adminSelector } from "../../services/selectors/user.selectors";
import { useGetAllUsersMutation } from "../../services/api/user.api";
import { IGetUserResponse } from "../../services/types/users.types";
import Loader from "../../components/loader/loader";

function EditPersonalDataPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const admin = useAppSelector(adminSelector);

  const [user, setUser] = useState<IGetUserResponse | undefined>(undefined);
  const [getUser, { isLoading }] = useGetAllUsersMutation();

  useEffect(() => {
    if (!id) return;
    getUser({ id, companyId: admin.companyId }).then((res) => {
      if ("data" in res) {
        const u = res.data.Users[0];
        u && setUser(u);
      }
    });
  }, [id, getUser, admin.companyId]);

  // const users = useAppSelector(usersSelector);
  // // const user = users.find((u) => u.Id.toString() === id);

  return (
    <article className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h2 className={styles.title}>משתמש קיים: פרטים אישיים</h2>
      {isLoading ? (
        <Loader />
      ) : user ? (
        <div className={styles.content}>
          <div className={styles.personal}>
            <h4 className={styles.permissionsTitle}>פרטים אישיים</h4>
            <img
              // src={`${user?.Avatar ? user.Avatar : avatarPlaceholder}`}
              src={avatarPlaceholder} //while no avatar in res
              alt="תמונת משתמש"
              className={styles.avatarPlaceholder}
            />
            <p className={styles.accountNumber}>מספר משתמש {user.Id}</p>
            <form className={styles.personalForm}>
              <div className={styles.personalFormCell}>
                <label
                  htmlFor="firstName"
                  className={`${styles.label} ${styles.label_type_personal}`}
                >
                  שם פרטי
                </label>
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  readOnly={true}
                  value={user?.FirstName || ""}
                  className={`${styles.input} ${styles.input_type_personal}`}
                />
              </div>
              <div className={styles.personalFormCell}>
                <label
                  htmlFor="lastName"
                  className={`${styles.label} ${styles.label_type_personal}`}
                >
                  שם משפחה
                </label>
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  readOnly={true}
                  value={user?.LastName || ""}
                  className={`${styles.input} ${styles.input_type_personal}`}
                />
              </div>
              <div className={styles.personalFormCell}>
                <label
                  htmlFor="passport"
                  className={`${styles.label} ${styles.label_type_personal}`}
                >
                  תעודת זהות
                </label>
                <input
                  type="text"
                  name="passport"
                  id="passport"
                  readOnly={true}
                  value={user?.Passport || ""}
                  className={`${styles.input} ${styles.input_type_personal}`}
                />
              </div>
              <div className={styles.personalFormCell}>
                <label
                  htmlFor="birth"
                  className={`${styles.label} ${styles.label_type_personal}`}
                >
                  תאריך לידה
                </label>
                <input
                  // type="date"
                  type="text"
                  name="birth"
                  id="birth"
                  readOnly={true}
                  value={
                    user?.Birth
                      ? new Date(user.Birth).toISOString().split("T")[0]
                      : ""
                  }
                  className={`${styles.input} ${styles.input_type_personal}`}
                />
              </div>
              <div className={styles.personalFormCell}>
                <label
                  htmlFor="DateRegister"
                  className={`${styles.label} ${styles.label_type_personal}`}
                >
                  תאריך יצירה
                </label>
                <input
                  // type="date"
                  type="text"
                  name="DateRegister"
                  id="DateRegister"
                  readOnly={true}
                  value={
                    user.DateRegister
                      ? new Date(user.DateRegister).toISOString().split("T")[0]
                      : ""
                  }
                  className={`${styles.input} ${styles.input_type_personal}`}
                />
              </div>
              {/*<div className={styles.personalFormCell}>*/}
              {/*  <label*/}
              {/*    htmlFor="country"*/}
              {/*    className={`${styles.label} ${styles.label_type_personal}`}*/}
              {/*  >*/}
              {/*    מדינה*/}
              {/*  </label>*/}
              {/*  <input*/}
              {/*    type="text"*/}
              {/*    name="country"*/}
              {/*    id="country"*/}
              {/*    readOnly={true}*/}
              {/*    value={user?.Country || ""}*/}
              {/*    className={`${styles.input} ${styles.input_type_personal}`}*/}
              {/*  />*/}
              {/*</div>*/}
              <div className={styles.personalFormCell}>
                <label
                  htmlFor="address"
                  className={`${styles.label} ${styles.label_type_personal}`}
                >
                  כתובת מגורים
                </label>
                <input
                  type="text"
                  name="address"
                  id="address"
                  readOnly={true}
                  value={user?.Address || ""}
                  className={`${styles.input} ${styles.input_type_personal}`}
                />
              </div>
              <div className={styles.personalFormCell}>
                <label
                  htmlFor="email"
                  className={`${styles.label} ${styles.label_type_personal}`}
                >
                  אימייל
                </label>
                <input
                  type="text"
                  name="email"
                  id="email"
                  readOnly={true}
                  value={user?.Email || ""}
                  className={`${styles.input} ${styles.input_type_personal}`}
                />
              </div>
              <div className={styles.personalFormCell}>
                <label
                  htmlFor="phone"
                  className={`${styles.label} ${styles.label_type_personal}`}
                >
                  נייד
                </label>
                <input
                  type="text"
                  name="phone"
                  id="phone"
                  readOnly={true}
                  value={user?.Phone || ""}
                  className={`${styles.input} ${styles.input_type_personal}`}
                />
              </div>
            </form>
          </div>
          {/*<div className={styles.permissions}>*/}
          {/*  <h4 className={styles.permissionsTitle}>הרשאות מנוי</h4>*/}
          {/*  <form className={styles.permissionsForm}>*/}
          {/*    <label htmlFor="company" className={`${styles.label}`}>*/}
          {/*      שיוך חברה*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      name="company"*/}
          {/*      id="company"*/}
          {/*      value="דיסקונט"*/}
          {/*      readOnly={true}*/}
          {/*      className={`${styles.input}`}*/}
          {/*    />*/}
          {/*    <label htmlFor="permission" className={`${styles.label}`}>*/}
          {/*      סוג הרשאה*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      name="permission"*/}
          {/*      id="permission"*/}
          {/*      value="מנוי"*/}
          {/*      readOnly={true}*/}
          {/*      className={`${styles.input}`}*/}
          {/*    />*/}
          {/*    <label htmlFor="plan" className={`${styles.label}`}>*/}
          {/*      סוג מנוי*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      name="plan"*/}
          {/*      id="plan"*/}
          {/*      value="פרימיום"*/}
          {/*      readOnly={true}*/}
          {/*      className={`${styles.input}`}*/}
          {/*    />*/}
          {/*    <label htmlFor="status" className={`${styles.label}`}>*/}
          {/*      סטטוס*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      name="status"*/}
          {/*      id="status"*/}
          {/*      value="פעיל"*/}
          {/*      readOnly={true}*/}
          {/*      className={`${styles.input}`}*/}
          {/*    />*/}
          {/*    <label htmlFor="instructor" className={`${styles.label}`}>*/}
          {/*      שיוך מדריך*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      name="instructor"*/}
          {/*      id="instructor"*/}
          {/*      value="ינון איבגי"*/}
          {/*      readOnly={true}*/}
          {/*      className={`${styles.input}`}*/}
          {/*    />*/}
          {/*    <label htmlFor="therapist" className={`${styles.label}`}>*/}
          {/*      שיוך מטפל*/}
          {/*    </label>*/}
          {/*    <input*/}
          {/*      type="text"*/}
          {/*      name="therapist"*/}
          {/*      id="therapist"*/}
          {/*      value="ינון איבגי"*/}
          {/*      readOnly={true}*/}
          {/*      className={`${styles.input}`}*/}
          {/*    />*/}
          {/*  </form>*/}
          {/*</div>*/}
        </div>
      ) : (
        <p className={`${styles.text}`}>משתמש לא נמצא </p>
      )}
    </article>
  );
}
export default EditPersonalDataPage;
